<template>
  <div>
    <div>
      <svg
        id="Component_9_1"
        :class="'Component_9_1' + content.objectid"
        data-name="Component 9 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 27 27"
      >
        <g
          id="Group_92"
          data-name="Group 92"
          transform="translate(-476 404) rotate(180)"
        >
          <g
            id="Path_20"
            data-name="Path 20"
            transform="translate(-503 377)"
           :fill="!toggleContentShare ? '#161616' : '#ec1c65'"
          >
            <path
              d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
              stroke="none"
            />
            <path
              d="M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
              stroke="none"
              fill="red"
            />
          </g>
          <g
            id="share_black_24dp"
            transform="translate(-481.376 398.624) rotate(180)"
          >
            <path
              id="Path_12"
              data-name="Path 12"
              d="M0,0H16.248V16.248H0Z"
              fill="none"
            />
            <path
              id="Path_13"
              :class="'Path_13' + content.objectid"
              data-name="Path 13"
              d="M13.155,11.532a1.971,1.971,0,0,0-1.327.521L7,9.244a2.216,2.216,0,0,0,.061-.474A2.216,2.216,0,0,0,7,8.3l4.773-2.782a2.026,2.026,0,1,0-.65-1.483,2.216,2.216,0,0,0,.061.474L6.412,7.287a2.031,2.031,0,1,0,0,2.965l4.82,2.816a1.91,1.91,0,0,0-.054.44,1.977,1.977,0,1,0,1.977-1.977Zm0-8.178a.677.677,0,1,1-.677.677A.679.679,0,0,1,13.155,3.354ZM5.031,9.447a.677.677,0,1,1,.677-.677A.679.679,0,0,1,5.031,9.447ZM13.155,14.2a.677.677,0,1,1,.677-.677A.679.679,0,0,1,13.155,14.2Z"
              transform="translate(-0.969 -0.646)"
              fill="#ffffff"
            />
          </g>
        </g>
      </svg>
    </div>
    <transition>
      <contentShare
        v-if="toggleContentShare"
        :content="shareContent"
        :closePopup="() => toggleContentSharePopup(false)"
      ></contentShare>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  data() {
    return {
      toggleContentShare: false,
      shareContent: null,
    };
  },
  methods: {
    toggleContentSharePopup(state) {
      if (state) {
        this.shareContent = this.content;
      }
      this.toggleContentShare = state;
    },
  },
  mounted() {

    // document.getElementById("Component_9_1").addEventListener("mouseover", () => {
    //   document.getElementById("Path_13").setAttribute("fill", "white")
    // })
    // document.getElementById("Component_9_1").addEventListener("mouseout", () => {
    //   document.getElementById("Path_13").setAttribute("fill", "#848486")
    // })


    try {
        document.getElementsByClassName(`Component_9_1${this.content.objectid}`)[0].addEventListener("mouseover", () => {
          document.getElementsByClassName(`Path_13${this.content.objectid}`)[0].setAttribute("fill", "white")
        });

        document.getElementsByClassName(`Component_9_1${this.content.objectid}`)[0].addEventListener("mouseout", () => {
          document.getElementsByClassName(`Path_13${this.content.objectid}`)[0].setAttribute("fill", "#ffffff")
        })

    } catch (e) {
      
    }


  },
  components: {
    contentShare: () =>
      import(
        /* webpackChunkName: "contentshare" */ "@/components/Popups/contentShare.vue"
      ),
  },
};
</script>


<style lang="scss">
@import "./detailShare.scss";
@import "@/sass/_variables.scss";

.iconouterfill{
  fill:$like-icon-outerfill
}

@media only screen and (min-width: 1000px) {
    #Component_8_1:hover g { 
      fill:$clr-likebutton-hover;
  }
}

</style>
